<template>

 
  <ClientOnly>

    <Teleport to="#app">
 
      <UiModal
        v-if="activeModal"
        :title="'Change password'"
        :close-button-name="'Close'"
        :primary-button-name="'Save'"
        :primary-button-is-loading="isSaving"
        :is-auto-height="true"
        :width="'100%'"
        :max-width="'400px'"
        @primary="savePassword"         
        @close="closeModal"         
        >        
        
        <div class="context_list">
    
          <div class="row">
            <div class="label">
              {{ $t('profile_menu_change_password_old_pass') }} 
            </div>
            <div class="field_input">
              <input type="password" v-model="in_old_password" placeholder="">
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_change_password_new_pass') }} 
            </div>
            <div class="field_input">
              <input type="password" v-model="in_new_password" placeholder="">
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_change_password_new_pass2') }} 
            </div>
            <div class="field_input">
              <input type="password" v-model="in_repeat_password" placeholder="">
            </div>
          </div>
 
        
  
        </div>
      </UiModal>
    </Teleport>    
  </ClientOnly>

</template>


<script setup>
 


const emits = defineEmits(['close']);
const { $popup, $api } = useNuxtApp();
const { t } = useI18n();

const props = defineProps({
  userId: {
    type: Number,
  },
  oldPassword: {
    type: String,
    default: ''
  },
  active: {
    type: Boolean,
    default: false
  },
})


var activeModal = ref(props.active)
var isSaving = ref(false)
const in_old_password = ref(props.oldPassword)
const in_new_password = ref(props.newPassword)
const in_repeat_password = ref(props.repeatPassword)

  
const closeModal = function(){
  activeModal.value = false;
  emits('close');
}

const savePassword = function(){

  in_old_password.value = in_old_password.value || '';
  in_new_password.value = in_new_password.value || '';
  in_repeat_password.value = in_repeat_password.value || '';

  if(!in_old_password.value.length || !in_new_password.value.length || !in_repeat_password.value.length){
    $popup.error(t('profile_menu_change_password_error_fill_in_all'));
    return false;
  }

  if(in_new_password.value != in_repeat_password.value){
    $popup.error(t('profile_menu_change_password_error_passwords'));
    return false;
  }

  isSaving.value = true;

  $api.patchProfilePassword(props.userId, {
    password_old: in_old_password.value,
    password_new: in_new_password.value,
    password_new2: in_repeat_password.value
  })
  .then((res) => {
    isSaving.value = false;
    $popup.success(t('profile_menu_change_password_message_save_success'));
    closeModal();
  })
  .catch(function (error) {
    $popup.error('Wrong password');
    isSaving.value = false;
  });

}

watch(() => props.active, (new_val) => {
  activeModal.value = new_val
})

</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }


  /*  */

  .context_list{ 
  }
 
</style>
